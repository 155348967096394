<template>
  <div id="mainpopup__mobile">
    <div class="mainpopup__body">
      <h4>{{ data.title }}</h4>
      <section>
        <img
          v-if="data.imageurl_mobile"
          :src="data.imageurl_mobile"
          width="100%"
        />
        <p>{{ data.content }}</p>
      </section>
      <button
        v-if="data.showdetail"
        @click="
          closeByEvent('detail')
          $emit('close')
        "
      >
        자세히보기
      </button>
    </div>

    <div v-if="!data.hidebuttons" class="mainpopup__footer">
      <p
        @click="
          closeByEvent(data.popup_hiding_type)
          $emit('close')
        "
        v-if="data.popup_hiding_type"
      >
        <span v-if="data.popup_hiding_type === 'today'"
          >오늘 하루 보지 않기</span
        >
        <span v-else-if="data.popup_hiding_type === 'week'"
          >일주일동안 보지 않기</span
        >
        <span v-else-if="data.popup_hiding_type === 'forever'"
          >더 이상 보지 않기</span
        >
      </p>
      <button
        @click="
          closeByEvent('submit')
          $emit('close')
        "
      >
        확인
      </button>
    </div>
    <div v-if="!data.hidebuttons" class="mainpopup__back"></div>
  </div>
</template>

<script>
export default {
  name: 'MainPopup',
  props: ['closeByEvent', 'data'],
}
</script>

<style lang="scss" scoped>
#mainpopup__mobile {
  .mainpopup__body {
    margin: 0px 16px;
    padding-top: 16px;
    padding-bottom: 24px;

    h4 {
      font-size: 24px;
      letter-spacing: -1.17px;
    }

    section {
      max-height: 320px;
      overflow: auto;
      margin-top: 17px;

      img {
        display: block;
        margin-bottom: 17px;
      }

      div {
        width: 100%;
        height: 24px;
      }

      p {
        font-size: 16px;
        word-break: keep-all;
        white-space: pre-line;
      }
    }

    button {
      width: 96px;
      height: 40px;
      background-color: white;
      border: 1px solid #000000;
      color: black;
      border-radius: 4px;
      font-size: 14px;
      margin-top: 32px;
    }
  }

  .mainpopup__back {
    width: 100%;
    height: 72px;
  }

  .mainpopup__footer {
    width: 100%;
    height: 71px;
    position: absolute;
    bottom: 0px;
    border-top: 1px solid #eeeeee;
    overflow: hidden;

    p {
      font-size: 14px;
      letter-spacing: -0.68px;
      color: #5e5e5e;
      float: left;
      margin-top: 25px;
      margin-left: 16px;
    }

    button {
      float: right;
      margin-right: 16px;
      width: 72px;
      height: 40px;
      border: none;
      border-radius: 4px;
      background-color: black;
      color: white;
      font-size: 14px;
      margin-top: 15px;
    }
  }
}
</style>
