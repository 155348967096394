import { dialogOpenFixed, dialogCloseFixed } from '../config/dialogFixedConfig'
import PlainText from '../components/dialog/common/PlainText.vue'
import BlackButtonText from '../components/dialog/common/BlackButtonText.vue'
import SelectButtonText from '../components/dialog/common/SelectButtonText.vue'

import PlainTextMobile from '../components/dialog/common/PlainTextMobile.vue'
import BlackButtonTextMobile from '../components/dialog/common/BlackButtonTextMobile.vue'
import SelectButtonTextMobile from '../components/dialog/common/SelectButtonTextMobile.vue'

import MainPopup from '../components/dialog/common/MainPopup.vue'
import MainPopupMobile from '../components/dialog/common/MainPopupMobile.vue'

export function openPlainText(modal, title, content, event, force) {
  let dialogScrollSave = window.scrollY
  if (location.pathname.split('?')[0].indexOf('_m') === -1) {
    modal.show(
      PlainText,
      {
        modal: modal,
        title: title,
        content: content,
        closeByEvent: (e) => {
          if (event) {
            dialogCloseFixed(dialogScrollSave)
            event(e)
          }
        },
      },
      {
        name: 'common-plain-text',
        width: '500px',
        height: '239px',
        transition: 'nice-modal-fade',
        scrollable: true,
      },
      {
        'before-open': (result) => {
          dialogOpenFixed(dialogScrollSave)
        },
        closed: (result) => {
          if (force) force()
          dialogCloseFixed(dialogScrollSave)
        },
      },
    )
  } else {
    modal.show(
      PlainTextMobile,
      {
        modal: modal,
        title: title,
        content: content,
        closeByEvent: (e) => {
          if (event) {
            dialogCloseFixed(dialogScrollSave)
            event(e)
          }
        },
      },
      {
        name: 'common-plain-text',
        width: '92%',
        height: 'auto',
        transition: 'nice-modal-fade',
        scrollable: true,
      },
      {
        'before-open': (result) => {
          dialogOpenFixed(dialogScrollSave)
        },
        closed: (result) => {
          if (force) force()
          dialogCloseFixed(dialogScrollSave)
        },
      },
    )
  }
}

export function openBlackButtonText(modal, title, content, button, event) {
  let dialogScrollSave = window.scrollY
  if (location.pathname.split('?')[0].indexOf('_m') === -1) {
    modal.show(
      BlackButtonText,
      {
        modal: modal,
        title: title,
        content: content,
        button: button,
        closeByEvent: (e) => {
          if (event) {
            dialogCloseFixed(dialogScrollSave)
            event(e)
          }
        },
      },
      {
        name: 'common-blackbutton-text',
        width: '500px',
        height: '300px',
        transition: 'nice-modal-fade',
        scrollable: true,
      },
      {
        'before-open': (result) => {
          dialogOpenFixed(dialogScrollSave)
        },
        closed: (result) => {
          dialogCloseFixed(dialogScrollSave)
        },
      },
    )
  } else {
    modal.show(
      BlackButtonTextMobile,
      {
        modal: modal,
        title: title,
        content: content,
        button: button,
        closeByEvent: (e) => {
          if (event) {
            dialogCloseFixed(dialogScrollSave)
            event(e)
          }
        },
      },
      {
        name: 'common-blackbutton-text',
        width: '92%',
        height: 'auto',
        transition: 'nice-modal-fade',
        scrollable: true,
      },
      {
        'before-open': (result) => {
          dialogOpenFixed(dialogScrollSave)
        },
        closed: (result) => {
          dialogCloseFixed(dialogScrollSave)
        },
      },
    )
  }
}

export function openSelectButtonText(
  modal,
  title,
  content,
  button1,
  button2,
  event,
) {
  let dialogScrollSave = window.scrollY
  if (location.pathname.split('?')[0].indexOf('_m') === -1) {
    modal.show(
      SelectButtonText,
      {
        modal: modal,
        title: title,
        content: content,
        button1: button1,
        button2: button2,
        closeByEvent: (e) => {
          if (event) {
            dialogCloseFixed(dialogScrollSave)
            event(e)
          }
        },
      },
      {
        name: 'common-selectbutton-text',
        width: '500px',
        height: 'auto',
        transition: 'nice-modal-fade',
        scrollable: true,
      },
      {
        'before-open': (result) => {
          dialogOpenFixed(dialogScrollSave)
        },
        closed: (result) => {
          dialogCloseFixed(dialogScrollSave)
        },
      },
    )
  } else {
    modal.show(
      SelectButtonTextMobile,
      {
        modal: modal,
        title: title,
        content: content,
        button1: button1,
        button2: button2,
        closeByEvent: (e) => {
          if (event) {
            dialogCloseFixed(dialogScrollSave)
            event(e)
          }
        },
      },
      {
        name: 'common-selectbutton-text',
        width: '92%',
        height: 'auto',
        transition: 'nice-modal-fade',
        scrollable: true,
      },
      {
        'before-open': (result) => {
          dialogOpenFixed(dialogScrollSave)
        },
        closed: (result) => {
          dialogCloseFixed(dialogScrollSave)
        },
      },
    )
  }
}

export function openMainPopup(modal, data, event) {
  let dialogScrollSave = window.scrollY
  if (location.pathname.split('?')[0].indexOf('_m') === -1) {
    modal.show(
      MainPopup,
      {
        modal: modal,
        data: data,
        closeByEvent: (e) => {
          if (event) {
            dialogCloseFixed(dialogScrollSave)
            event(e)
          }
        },
      },
      {
        name: 'common-main-popup',
        width: '496px',
        height: 'auto',
        transition: 'nice-modal-fade',
        scrollable: true,
      },
      {
        'before-open': (result) => {
          dialogOpenFixed(dialogScrollSave)
        },
        closed: (result) => {
          dialogCloseFixed(dialogScrollSave)
        },
      },
    )
  } else {
    modal.show(
      MainPopupMobile,
      {
        modal: modal,
        data: data,
        closeByEvent: (e) => {
          if (event) {
            dialogCloseFixed(dialogScrollSave)
            event(e)
          }
        },
      },
      {
        name: 'common-main-popup-mobile',
        width: '92%',
        height: 'auto',
        transition: 'nice-modal-fade',
        scrollable: true,
      },
      {
        'before-open': (result) => {
          dialogOpenFixed(dialogScrollSave)
        },
        closed: (result) => {
          dialogCloseFixed(dialogScrollSave)
        },
      },
    )
  }
}
