<template>
  <div>
    <div
      class="plaintext__container"
      :style="{ 'padding-top': content.indexOf('\n') == -1 ? '15px' : '0px' }"
    >
      <p class="plaintext__content">{{ content }}</p>
    </div>

    <button
      @click="
        $emit('close')
        closeByEvent('close')
      "
      class="plaintext__submit"
      :style="{ 'margin-top': content.indexOf('\n') == -1 ? '40px' : '30px' }"
    >
      확인
    </button>
  </div>
</template>

<script>
export default {
  name: 'PlainTextMobile',
  props: ['closeByEvent', 'title', 'content'],
}
</script>

<style lang="scss" scoped>
.plaintext__container {
  position: relative;
  text-align: center;

  .plaintext__content {
    min-height: 24px;
    white-space: pre-line;
    padding-top: 22px;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    position: relative;
    width: 90%;
    margin-left: 5%;
  }
}

.plaintext__submit {
  position: relative;
  left: calc(50% - 50px);
  width: 100px;
  height: 40px;
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(211, 211, 211);
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
