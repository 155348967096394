import Vue from 'vue'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import Hammer from 'hammerjs'
import VModal from 'vue-js-modal'
import Snackbar from 'vuejs-snackbar'
import App from './App.vue'
import { initSentry } from './lib/sentry'
import { router } from './router'
import { store } from './store'
import './plugins/axios'

declare global {
  interface ImportMeta {
    env: {
      VITE_API_URL?: string
      VITE_SENTRY_ENABLED?: string
      VITE_RELEASE_VERSION?: string
    }
  }
}

Vue.config.productionTip = false

Vue.use(VModal, { dynamic: true })

Vue.component('snackbar', Snackbar)

Vue.directive('swipeleft', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el, {
        touchAction: 'pan-y',
      })
      mc.on('swipeleft', binding.value)
    }
  },
})

Vue.directive('swiperight', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el, {
        touchAction: 'pan-y',
      })
      mc.on('swiperight', binding.value)
    }
  },
})

const getServerTime = async () => {
  const result = await fetch('https://serve.yookgak.com/api/v1/get-datetime', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const data = await result.json()
  const datetime = new Date(data)
  store.state.timeDiff = differenceInMilliseconds(datetime, new Date())
}

getServerTime()
initVueSystem()

store.dispatch('getCategoryList')

async function initVueSystem() {
  initSentry(router)

  await store.dispatch('clientInit')
  await store.dispatch('preloadGoodsList')

  store.dispatch('csrfToken')

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
}
