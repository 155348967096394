<template>
  <div id="blackbutton__mobile">
    <div class="blackbutton__container">
      <h4 class="blackbutton__title">{{ title }}</h4>
      <p class="blackbutton__content">{{ content }}</p>
    </div>

    <button
      @click="
        $emit('close')
        closeByEvent('close')
      "
      class="blackbutton__submit"
    >
      <span v-if="button">{{ button }}</span
      ><span v-else>확인</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BlackButtonTextMobile',
  props: ['closeByEvent', 'title', 'content', 'button'],
}
</script>

<style lang="scss" scoped>
#blackbutton__mobile {
  .blackbutton__container {
    padding-top: 27px;
    text-align: center;

    h4 {
      font-size: 20px;
      line-height: 24px;
    }

    p {
      margin-top: 15px;
      font-size: 16px;
      line-height: 22px;
      min-height: 44px;
      white-space: pre-line;
    }
  }

  button {
    background-color: #000000;
    border: none;
    width: 88%;
    height: 50px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin: {
      left: 6%;
      top: 20px;
      bottom: 20px;
    }
  }
}
</style>
