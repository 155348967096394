import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/database'
import 'firebase/compat/storage'

const config = {
  apiKey: 'AIzaSyAlGHNNIvQRpxl0mDwZ_c2DOHIg0Fufc-0',
  authDomain: 'jyg-custom.firebaseapp.com',
  databaseURL: 'https://jyg-custom.firebaseio.com',
  projectId: 'jyg-custom',
  storageBucket: 'jyg-custom.appspot.com',
  messagingSenderId: '222150107123',
}

firebase.initializeApp(config)

export const db = firebase.database()
export const fstore = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
