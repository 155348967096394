<template>
  <div>
    <div class="blackbutton__container">
      <h4 class="blackbutton__title">{{ title }}</h4>
      <p class="blackbutton__content">{{ content }}</p>
    </div>

    <button
      @click="
        $emit('close')
        closeByEvent('close')
      "
      class="blackbutton__submit"
    >
      <span v-if="button">{{ button }}</span
      ><span v-else>확인</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BlackButtonText',
  props: ['closeByEvent', 'title', 'content', 'button'],
}
</script>

<style lang="scss" scoped>
.blackbutton__container {
  position: relative;
  top: 40px;
  text-align: center;

  .blackbutton__title {
    font-size: 28px;
    font-weight: bold;
    line-height: 24px;
  }

  .blackbutton__content {
    margin-top: 40px;
    font-size: 21px;
    line-height: 30px;
    min-height: 60px;
    max-height: unset;
    white-space: pre-line;
  }
}

.blackbutton__submit {
  position: absolute;
  bottom: 30px;
  width: 440px;
  height: 60px;
  background-color: black;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: bold;
  margin-left: 30px;
}
</style>
