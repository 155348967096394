import { store } from '../store'

export function dialogOpenFixed(tp) {
  if (!store.state.isMobile) {
    document.body.classList.add('v--modal-scroll-fixed')
    document.body.style.top = '-' + tp + 'px'
  } else {
    document.body.classList.add('v--modal-scroll-fixed-mobile')
  }
}

export function dialogCloseFixed(tp) {
  if (!store.state.isMobile) {
    document.body.classList.remove('v--modal-scroll-fixed')
    window.scrollTo(0, tp)
    document.body.style.top = 'unset'
  } else {
    document.body.classList.remove('v--modal-scroll-fixed-mobile')
  }
}
