<template>
  <div />
</template>

<script>
export default {
  mounted() {
    window.location.reload()
  },
}
</script>
