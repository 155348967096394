import { CouponData, UserCoupon } from '../models/UserCoupon'
import { getCollection, getReference } from '../lib/firebase'

class CouponService {
  userCouponCol(uid: string) {
    return getCollection('customers/user/information/' + uid + '/coupon_record')
  }

  userCouponDoc(uid: string, id: string) {
    return this.userCouponCol(uid).doc(id)
  }

  async getUserCoupons(uid: string) {
    const snap = await this.userCouponCol(uid).get()
    return snap.docs.map(
      (doc) => new UserCoupon(doc.id, doc.data() as CouponData),
    )
  }

  couponIssueRef(couponKey: string) {
    return getReference('couponlist').child('publish').child(couponKey)
  }

  /**
   * 사용자 보유 쿠폰 조회
   */
  async findUserCouponByKey(uid: string, couponKey: string) {
    const snap = await this.userCouponCol(uid)
      .where('coupon_key', '==', couponKey)
      .get()
    if (snap.empty) return null

    return new UserCoupon(snap.docs[0].id, snap.docs[0].data() as CouponData)
  }

  /**
   * 사용자 보유 쿠폰 목록 조회
   */
  async findUserCouponListByKey(uid: string, couponKeys: string[]) {
    const snap = await this.userCouponCol(uid)
      .where('coupon_key', 'in', couponKeys)
      .get()
    if (snap.empty) return null

    return snap.docs.map(
      (doc) => new UserCoupon(doc.id, doc.data() as CouponData),
    )
  }

  async removeUserCoupon(uid: string, id: string) {
    // TODO: MIGRATE_COUPON_RECORD - will be removed
    getReference('customers/' + uid + '/get_coupons/' + id).remove()

    // this.couponIssueRef(id).child(uid).remove()

    return await this.userCouponDoc(uid, id).delete()
  }
}

export const couponService = new CouponService()
