import axios from 'axios'
import Storage from '../lib/storage'

class HomeService {
  getCachedBannerDisplay() {
    // legacy data
    if (Storage.has('banner-result-etag')) {
      Storage.saveString(
        'home-banner-display-etag',
        Storage.getString('banner-result-etag'),
      )
      Storage.save('home-banner-display', Storage.get('banner-result'))
      Storage.remove('banner-result-etag')
      Storage.remove('banner-result')
    }

    return Storage.get('home-banner-display', [])
  }

  getCachedComponent() {
    // legacy data
    if (Storage.has('component-result-etag')) {
      Storage.saveString(
        'home-component-etag',
        Storage.getString('component-result-etag'),
      )
      Storage.save('home-component', Storage.get('component-result'))
      Storage.remove('component-result-etag')
      Storage.remove('component-result')
    }

    return Storage.get('home-component', [])
  }

  async getHomeBannerDisplay() {
    const etag = Storage.getString('home-banner-display-etag', 'none')

    const res = await axios.get('/v1/home/banner-display', {
      headers: {
        'if-none-match': etag,
      },
    })

    if (res.status === 304) {
      return null
    }

    Storage.save('home-banner-display', res.data.items)
    Storage.saveString('home-banner-display-etag', res.headers.etag)
    return res.data.items
  }

  async getHomeComponents() {
    const etag = Storage.getString('home-component-etag', 'none')

    const res = await axios.get('/v1/home/component', {
      headers: {
        'if-none-match': etag,
      },
    })

    if (res.status === 304) {
      return null
    }

    Storage.save('home-component', res.data.items)
    Storage.saveString('home-component-etag', res.headers.etag)
    return res.data.items
  }
}

export const homeService = new HomeService()
