interface IdOption {
  id: string
  option: number
}

export interface CouponData {
  condition_common: any
  condition_use: any
  history: any
  reward: any
  coupon_key: string
  uid: string
}

class CouponConditionCommon {
  readonly description: string
  readonly fixed_date: boolean
  readonly name: string
  readonly use_duration: string
  readonly use_start: Date
  readonly use_end: Date
  readonly apponly: boolean
  readonly giftonly: boolean

  constructor(json: any) {
    this.description = json.description
    this.fixed_date = json.fixed_date
    this.name = json.name
    this.use_duration = json.use_duration
    this.use_start = json.use_start.toDate()
    this.use_end = json.use_end.toDate()
    this.apponly = json.apponly
    this.giftonly = json.giftonly
  }
}

class CouponConditionUse {
  readonly id_option: IdOption[] | null
  readonly price_max: number
  readonly price_min: number
  readonly count_min: number
  readonly subtype: string
  readonly target: string
  readonly type: string

  constructor(json: any) {
    this.id_option = json.id_option || null
    this.price_max = json.price_max
    this.price_min = json.price_min
    this.count_min = json.count_min
    this.subtype = json.subtype
    this.target = json.target
    this.type = json.type
  }
}

class CouponReward {
  readonly pricelimit: string
  readonly type: string
  readonly subtype: string
  readonly value: number
  readonly target: string | null
  readonly id_option: IdOption[] | null

  constructor(json) {
    this.pricelimit = json.pricelimit
    this.type = json.type
    this.subtype = json.subtype
    this.value = json.value
    this.target = json.target || null
    this.id_option = json.id_option || null
  }
}

class CouponRecordHistory {
  readonly get_time: Date
  readonly order_key: string | null
  readonly use_time: Date | null

  constructor(json: any) {
    this.get_time = json.get_time.toDate()
    this.order_key = json.order_key || null
    this.use_time = json.use_time ? json.use_time.toDate() : null
  }
}

export class UserCoupon {
  readonly data: CouponData

  readonly id: string
  readonly coupon_key: string
  readonly condition_common: CouponConditionCommon
  readonly condition_use: CouponConditionUse
  readonly history: CouponRecordHistory
  readonly reward: CouponReward
  readonly uid: string

  get webonly(): boolean {
    return !this.condition_common.apponly && !this.condition_common.giftonly
  }

  /**
   * 쿠폰 사용 가능 여부 확인
   * @param {Date} date
   * @returns {boolean}
   */
  isAvailable(date: Date): boolean {
    if (this.history.use_time) return false
    return (
      this.condition_common.use_start <= date &&
      date <= this.condition_common.use_end
    )
  }

  constructor(id: string, data: CouponData) {
    this.id = id
    this.data = data
    this.condition_common = new CouponConditionCommon(data.condition_common)
    this.condition_use = new CouponConditionUse(data.condition_use)
    this.history = new CouponRecordHistory(data.history)
    this.reward = new CouponReward(data.reward)
    this.coupon_key = data.coupon_key
    this.uid = data.uid
  }
}
