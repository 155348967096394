export class Storage {
  static save(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  static saveString(key: string, value: string) {
    localStorage.setItem(key, value)
  }

  static saveNumber(key: string, value: number) {
    localStorage.setItem(key, value.toString())
  }

  static saveBoolean(key: string, value: boolean) {
    localStorage.setItem(key, value.toString())
  }

  static has(key: string) {
    return localStorage.getItem(key) !== null
  }

  static get<T>(key: string, defaultValue: T = null): T {
    return JSON.parse(localStorage.getItem(key) || null) ?? defaultValue
  }

  static getString(key: string, defaultValue: string = '') {
    return localStorage.getItem(key) ?? defaultValue
  }

  static getNumber(key: string, defaultValue: number = 0) {
    return Number(localStorage.getItem(key) ?? defaultValue)
  }

  static getBoolean(key: string) {
    return localStorage.getItem(key) === 'true'
  }

  static remove(key: string) {
    localStorage.removeItem(key)
  }
}

export default Storage
