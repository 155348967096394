<template>
  <div id="selectbutton__mobile">
    <button class="dialog-close__mobile-btn" @click="$emit('close')">
      <i></i>
    </button>
    <h4 class="selectbutton__title">{{ title }}</h4>
    <p class="selectbutton__content">{{ content }}</p>

    <div class="selectbutton-button__area">
      <button @click="$emit('close')" btncolor="gray">{{ button1 }}</button>
      <button
        @click="
          $emit('close')
          closeByEvent('close')
        "
        btncolor="black"
      >
        {{ button2 }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectButtonTextMobile',
  props: ['closeByEvent', 'title', 'content', 'button1', 'button2'],
}
</script>

<style lang="scss" scoped>
#selectbutton__mobile {
  h4 {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    margin-top: -10px;
  }

  p {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    line-height: 22px;
    min-height: 44px;
    word-break: keep-all;
    white-space: pre-line;
    color: #4a4a4a;
    width: 90%;
    margin-left: 5%;
  }

  .selectbutton-button__area {
    width: 92%;
    margin: {
      left: 4%;
      top: 21px;
    }
    overflow: hidden;
    padding-bottom: 15px;

    button {
      width: 50%;
      height: 60px;
      color: #fff;
      border: none;
      font-size: 16px;
      font-weight: 700;
      float: left;

      &[btncolor='gray'] {
        background-color: #acacac;
      }

      &[btncolor='black'] {
        background-color: #000000;
      }
    }
  }
}
</style>
