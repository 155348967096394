<template>
  <div>
    <div class="plaintext__container">
      <h4 class="plaintext__title">{{ title }}</h4>
      <p class="plaintext__content">{{ content }}</p>
    </div>

    <button
      @click="
        $emit('close')
        closeByEvent('close')
      "
      class="plaintext__submit"
    >
      확인
    </button>
  </div>
</template>

<script>
export default {
  name: 'PlainText',

  data() {
    return {}
  },

  props: ['closeByEvent', 'title', 'content'],
}
</script>

<style lang="scss" scoped>
.plaintext__container {
  position: relative;
  top: 37px;
  text-align: center;

  .plaintext__title {
    font-size: 19px;
    font-weight: bold;
    line-height: 22px;
  }

  .plaintext__content {
    margin-top: 22px;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-line;
    width: 90%;
    margin-left: 5%;
  }
}

.plaintext__submit {
  position: absolute;
  bottom: 40px;
  left: calc(50% - 42px);
  width: 84px;
  height: 30px;
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(211, 211, 211);
  font-size: 13px;
  font-weight: bold;
}
</style>
