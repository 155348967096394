<template>
  <div>
    <button class="dialog-close__btn" @click="$emit('close')"></button>
    <h3 class="applaunching__title">정육각 앱 설치 링크</h3>

    <p>
      휴대전화번호를 입력하시면<br />앱 설치 링크를 문자로 받아보실 수 있습니다.
    </p>
    <input
      @keyup="validation($event.target.value)"
      v-model="applaunchingPhone"
      placeholder="- 없이 입력해주세요."
      type="tel"
    />

    <button
      @click="applaunchingSend()"
      class="applaunching__submit"
      :activate="this.validationCheck.toString()"
    >
      문자 전송하기
    </button>
  </div>
</template>

<script>
import { db } from '../../../../config/firebaseConfig'

export default {
  name: 'ApplaunchingMessage',
  data() {
    return {
      applaunchingPhone: '',
      validationCheck: false,
    }
  },

  methods: {
    validation(e) {
      this.validationCheck = e.length === 10 || e.length === 11
    },

    applaunchingSend() {
      db.ref('realtime/smsRequest')
        .push({
          target: this.applaunchingPhone,
          message:
            "아래 링크를 통해 스토어에서 '설치하기'를 눌러주세요.\n▶ https://jeongyookgak.page.link/event_applaunch\n*앱을 통해 로그인하시면 바로 쿠폰이 지급됩니다.\n*앱 전용 쿠폰은 계정당 1회 지급됩니다.",
          title: '[정육각] 앱 설치 안내 문자',
          type: 'LMS',
        })
        .then(() => {
          this.$emit('close')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
h3 {
  margin-top: 15px;
  text-align: center;
  font-size: 30px;
  line-height: 28px;
}

p {
  margin-top: 24px;
  text-align: center;
  font-size: 18px;
  line-height: 25px;
}

input {
  margin-top: 20px;
  height: 42px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  width: 334px;
  border: 1px solid black;
  font-size: 16px;
}

.applaunching__submit {
  margin-top: 58px;
  width: 440px;
  border: none;
  height: 63px;
  background-color: #000;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-left: 30px;
  margin-bottom: 30px;

  &[activate='false'] {
    background-color: #9b9b9b;
    color: #b7b7b7;
    cursor: default;
  }
}
</style>
