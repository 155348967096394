import { getCookie } from '../lib/cookie'
import { serverTimeDate } from '../return/serverTimeDate'

export function fbPixel(event, data, id) {
  fetch(
    'https://graph.facebook.com/v12.0/108037319906638/events?access_token=EAAChxq2sQOoBABvvKwGZC6c38TR1ls5DrzEfg0rVTIgMuwvsy1qK4dDdvmnClYgeqpe0StEtLekakePTPQGVnrMByqfqmZBW1ChavlgtJnBaoDtNIpJbJqQTQGUxLbgvnbShVYzmoL4tmCSHYnZAa6fybO5kzMugRo5stV4aaEVsQieOXyRIT6ZAqaXZBxiZAhbM1aC1RgZCl95LGSmBA78',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: [
          {
            event_name: event,
            event_time: Math.floor(serverTimeDate().getTime() / 1000) - 32400,
            action_source: 'website',
            user_data: {
              client_ip_address: null,
              client_user_agent: null,
              country: [
                'fa34f97bf4bcb580fd7dab4cca45c67b9ac4fd4452e044a29eb3da8d11ac4a46',
              ],
              fbp: getCookie('_fbp'),
            },
            event_id: id,
            custom_data: data,
          },
        ],
        // test_event_code: 'TEST22961'
      }),
    },
  )
}
