<template>
  <div v-if="loading" class="backdrop">
    <div id="loading-spinner"></div>
  </div>
</template>

<script>
import lottie from 'lottie-web'
import * as animationData from './loading.json'
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },

  updated() {
    let lottieControl = lottie.loadAnimation({
      container: document.getElementById('loading-spinner'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    })

    lottieControl.play()
  },
}
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0px;
  z-index: 100000;

  #loading-spinner {
    position: absolute;
    width: 92px;
    height: 92px;
    left: calc(50% - 46px);
    top: calc(50% - 46px);
  }
}
</style>
