<template>
  <div class="selectbutton__container">
    <button
      style="top: -20px; margin-top: 0px"
      class="dialog-close__btn"
      @click="$emit('close')"
    >
      <i></i>
    </button>
    <h4 class="selectbutton__title">{{ title }}</h4>
    <p class="selectbutton__content">{{ content }}</p>

    <div class="selectbutton-button__area">
      <button @click="$emit('close')" btncolor="gray">{{ button1 }}</button>
      <button
        @click="
          $emit('close')
          closeByEvent('close')
        "
        btncolor="black"
      >
        {{ button2 }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectButtonText',
  props: ['closeByEvent', 'title', 'content', 'button1', 'button2'],
}
</script>

<style lang="scss" scoped>
.selectbutton__container {
  h4 {
    margin-top: 9px;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
  }

  p {
    text-align: center;
    margin-top: 31px;
    padding-bottom: 116px;
    font-size: 16px;
    line-height: 25px;
    min-height: 50px;
    word-break: keep-all;
    white-space: pre-line;
    width: 85%;
    margin-left: 7.5%;
  }

  .selectbutton-button__area {
    width: 100%;
    position: absolute;
    bottom: 0px;

    button {
      width: 50%;
      height: 60px;
      color: #fff;
      border: none;
      font-size: 16px;
      font-weight: 700;
      float: left;

      &[btncolor='gray'] {
        background-color: #acacac;
      }

      &[btncolor='black'] {
        background-color: #000000;
      }
    }
  }
}
</style>
