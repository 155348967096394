<template>
  <div>
    <div
      @click="optionDropdownControl($event)"
      v-if="Object.keys(productData).length > 0"
    >
      <button
        class="dialog-close__btn"
        @click="
          $emit('close')
          closeByEvent('close')
        "
      ></button>
      <h3 class="itemcart__title">{{ productData.detail_name }}</h3>

      <div class="itemcart__counter">
        <div class="itemcart__counter-centered">
          <button @click="itemCountChange(-1)" class="itemcart__counter-btn">
            <i class="itemcart__counter-minus"></i>
          </button>
          <div class="itemcart__counter-number">{{ itemCount }}</div>
          <button @click="itemCountChange(1)" class="itemcart__counter-btn">
            <i class="itemcart__counter-plus"></i>
          </button>
        </div>
      </div>

      <p class="itemcart__option-text">옵션선택</p>

      <div class="itemcart__dropdown">
        <button id="itemcart__dropdown-btn" class="itemcart__dropdown-btn">
          {{ productData.selects[optionSelected].name
          }}<i id="itemcart__dropdown-arrow"></i>
        </button>
        <div
          v-if="optionDropdownFlag === true"
          class="itemcart__dropdown-content"
        >
          <div
            class="itemcart__dropdown-list"
            v-for="(option, index) of productData.selects"
            :key="index"
          >
            <div @click="optionSelect(index)">{{ option.name }}</div>
          </div>
        </div>
      </div>

      <div class="itemcart__price">
        <p class="itemcart__price-pre" v-if="productData.preprice">
          {{ (itemCount * productData.preprice) | priceForm }}원
        </p>
        <p class="itemcart__price-price">
          {{ (itemCount * productData.price) | priceForm }}원
        </p>
      </div>

      <div class="itemcart__submit">
        <button
          @click="addToCart('now')"
          class="itemcart__submit-btn submit-btn__gray"
        >
          바로구매
        </button>
        <button
          @click="addToCart('cart')"
          class="itemcart__submit-btn submit-btn__black"
        >
          장바구니
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import find from 'lodash/find'
import { db, auth } from '../../../config/firebaseConfig'
import priceForm from '../../../filters/priceForm'
import { dialogCloseFixed } from '../../../config/dialogFixedConfig'
import { fbPixel } from '../../../services/fbPixel'
import { serverTimeDate } from '../../../return/serverTimeDate'
import { getCookie } from '../../../lib/cookie'

export default {
  name: 'ItemCart',

  data() {
    return {
      itemCount: 1,
      productData: {},
      optionSelected: 0,
      optionDropdownFlag: false,
    }
  },
  props: ['productKey', 'userEmail'],

  filters: { priceForm },

  created() {
    this.productData = find(this.$store.state.goodsList, [
      'unique_key',
      this.productKey,
    ])
  },

  watch: {
    '$store.state.goodsList'() {
      this.productData = find(this.$store.state.goodsList, [
        'unique_key',
        this.productKey,
      ])
    },
  },

  methods: {
    addToCart(type) {
      db.ref(
        'customers/' +
          auth.currentUser.uid +
          '/cart/' +
          this.productData.selects[this.optionSelected].pid +
          '_' +
          this.productData.selects[this.optionSelected].pop,
      ).once('value', (cartSnaps) => {
        let cartSetCount = this.itemCount
        if (cartSnaps.val()) cartSetCount += cartSnaps.val().count

        db.ref(
          'customers/' +
            auth.currentUser.uid +
            '/cart/' +
            this.productData.selects[this.optionSelected].pid +
            '_' +
            this.productData.selects[this.optionSelected].pop,
        ).set({
          count: cartSetCount,
          timestamp: new Date().toISOString(),
        })

        if (type === 'now') {
          dialogCloseFixed(0)
          this.$router.push('/cart')
          ga(
            'send',
            'event',
            'cart',
            'step02_btn_buynow',
            this.productData.selects[this.optionSelected].pid,
          )
        } else {
          ga(
            'send',
            'event',
            'cart',
            'step02_btn_addcart',
            this.productData.selects[this.optionSelected].pid,
          )
        }
      })
      const fbEventId = getCookie('_ga') + '_' + serverTimeDate().getTime()
      fbPixel(
        'AddToCart',
        {
          content_ids: [this.productData.selects[this.optionSelected].pid],
          content_type: 'product',
          value: this.productData.price * this.itemCount,
          currency: 'KRW',
        },
        fbEventId,
      )
      // eslint-disable-next-line
      if (!wcs_add) var wcs_add = {}
      wcs_add['wa'] = 's_313ececb53dd'
      var _nasa = {}
      if (window.wcs) {
        _nasa['cnv'] = wcs.cnv('3', '1') // 전환유형, 전환가치 설정해야함. 설치매뉴얼 참고
        // eslint-disable-next-line
        wcs_do(_nasa)
      }

      fbq(
        'track',
        'AddToCart',
        {
          content_ids: [this.productData.selects[this.optionSelected].pid],
          content_type: 'product',
          value: this.productData.price * this.itemCount,
          currency: 'KRW',
        },
        {
          eventID: fbEventId,
        },
      )

      this.$emit('close')
    },

    optionDropdownControl(event) {
      if (event.target.id === '') {
        this.optionDropdownFlag = false
      } else {
        this.optionDropdownFlag = !this.optionDropdownFlag
      }
    },

    optionSelect(target) {
      this.optionSelected = target
    },

    itemCountChange(change) {
      if (this.itemCount + change > 0) {
        this.itemCount += change
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.itemcart__title {
  margin-top: 18px;
  text-align: center;
  font-size: 24px;
}

.itemcart__counter {
  overflow: hidden;
  text-align: center;
  margin-top: 40px;

  .itemcart__counter-centered {
    display: inline-block;

    .itemcart__counter-btn {
      width: 50px;
      height: 50px;
      border: 1px solid #e1dedf;
      background-color: white;
      display: block;
      float: left;

      .itemcart__counter-minus {
        width: 21px;
        height: 3px;
        display: inline-block;
        background: url(/assets/common/icon/minus_icon_1.svg) no-repeat;
        background-size: cover;
        position: relative;
        top: -4px;
      }

      .itemcart__counter-plus {
        width: 18px;
        height: 18px;
        display: inline-block;
        background: url(/assets/common/icon/plus_icon_1.svg) no-repeat;
        background-size: cover;
        position: relative;
        top: 2px;
      }
    }

    .itemcart__counter-number {
      width: 298px;
      height: 48px;
      border-top: 1px solid #e1dedf;
      border-bottom: 1px solid #e1dedf;
      line-height: 48px;
      font-size: 18px;
      float: left;
    }
  }
}

.itemcart__option-text {
  margin-left: 51px;
  margin-top: 14px;
  font-weight: bold;
  line-height: 24px;
  font-size: 16px;
}

.itemcart__dropdown {
  width: 396px;
  border: 1px solid #e1dedf;
  margin-left: 51px;
  margin-top: 6px;
  position: relative;
  z-index: 1;

  .itemcart__dropdown-btn {
    width: 396px;
    height: 50px;
    font-size: 18px;
    line-height: 1.33;
    position: relative;
    background-color: white;
    border: none;

    #itemcart__dropdown-arrow {
      display: block;
      width: 13px;
      height: 8px;
      background: url(/assets/common/icon/dropdown_arrow.svg) no-repeat;
      position: absolute;
      bottom: 19px;
      right: 18px;
    }
  }

  .itemcart__dropdown-content {
    background-color: white;
    z-index: 1;

    .itemcart__dropdown-list {
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
}

.itemcart__price {
  position: absolute;
  top: 280px;
  right: 51px;
  z-index: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  .itemcart__price-pre {
    font-size: 16px;
    font-weight: 500;
    color: #9e9e9e;
    text-decoration: line-through;
  }

  .itemcart__price-price {
    font-size: 24px;
    font-weight: bold;
  }
}

.itemcart__submit {
  position: absolute;
  width: 100%;
  bottom: 0px;
  overflow: hidden;

  .itemcart__submit-btn {
    width: 50%;
    height: 60px;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    float: left;
  }

  .submit-btn__gray {
    background-color: #acacac;
  }

  .submit-btn__black {
    background-color: black;
  }
}
</style>
