import { AxiosResponse } from 'axios'

export function isUnauthorizationError(response: AxiosResponse) {
  if (response) {
    return response.status === 401
  } else {
    return false
  }
}

export function isInvalidCsrfTokenError(response: AxiosResponse) {
  if (response) {
    return (
      response.status === 403 && response.data.message === 'invalid csrf token'
    )
  } else {
    return false
  }
}
