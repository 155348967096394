import {
  getFirestore,
  collection,
  doc,
  writeBatch,
  Firestore,
  getDocs,
  getDoc,
  query,
  where,
  WhereFilterOp,
  QueryConstraint,
  deleteDoc,
} from 'firebase/firestore'

export function getCollection(path: string, db = getFirestore()) {
  return new Collection(db, [path])
}

export function getDocument(path: string, db = getFirestore()) {
  return new Document(db, [path])
}

export function getBatch(db = getFirestore()) {
  return writeBatch(db)
}

abstract class QueryBuilder {
  constructor(
    readonly db: Firestore,
    readonly path: string[],
  ) {}

  abstract get(): Promise<any>
}

class Collection extends QueryBuilder {
  readonly constrains: QueryConstraint[] = []

  constructor(
    readonly db: Firestore,
    readonly path: string[],
  ) {
    super(db, path)
  }

  get ref() {
    return collection(this.db, this.path.join('/'))
  }

  doc(id: string) {
    return new Document(this.db, this.path.concat(id))
  }

  where(field: string, op: WhereFilterOp, value: any) {
    this.constrains.push(where(field, op, value))
    return this
  }

  get() {
    return getDocs(query(this.ref, ...this.constrains))
  }
}

class Document extends QueryBuilder {
  constructor(
    readonly db: Firestore,
    readonly path: string[],
  ) {
    super(db, path)
  }

  get ref() {
    return doc(this.db, this.path.join('/'))
  }

  collection(name: string) {
    return new Collection(this.db, this.path.concat(name))
  }

  get() {
    return getDoc(this.ref)
  }

  delete() {
    return deleteDoc(this.ref)
  }
}
