import { Database, getDatabase, ref, get, remove } from 'firebase/database'

export function getReference(path: string, db = getDatabase()) {
  return new Reference(db, [path])
}

abstract class QueryBuilder {
  constructor(
    readonly db: Database,
    readonly path: string[],
  ) {}

  abstract get(): Promise<any>
}

class Reference extends QueryBuilder {
  constructor(
    readonly db: Database,
    readonly path: string[],
  ) {
    super(db, path)
  }

  get ref() {
    return ref(this.db, this.path.join('/'))
  }

  child(path: string) {
    this.path.push(path)
    return this
  }

  get() {
    return get(this.ref)
  }

  remove() {
    return remove(this.ref)
  }
}
