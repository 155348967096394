import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../config/firebaseConfig'

export function initSentry(router: VueRouter) {
  if (import.meta.env.VITE_SENTRY_ENABLED) {
    Sentry.init({
      Vue,
      dsn: 'https://8dae59dfabeefb990f770bdee4acedea@o403090.ingest.us.sentry.io/4508208914890752',
      integrations: [Sentry.browserTracingIntegration({ router })],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/yookgak\.com\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

      release: import.meta.env.VITE_RELEASE_VERSION,
    })

    auth.onAuthStateChanged((user) => {
      if (user) {
        Sentry.setUser({
          id: user.uid,
          email: user.email,
        })
      } else {
        Sentry.setUser(null)
      }
    })
  }
}
